<template>
  <div>
    <cms-page :page-name="path" />
  </div>
</template>

<script setup lang="ts">
const route = useRoute();

const path = computed(() => (route.path === '/' ? 'homepage' : route.path));

</script>

<style scoped>

</style>
